import React from 'react';

import Section from '@components/common/Section';
import TrendingProductsWrapper from '@components/pages/home/trending-products/TrendingProductsWrapper';
import { useTranslation } from '@hooks/useTranslation';
import { IHomePageComponentDataObject } from '@interfaces/home';

interface ITrendingProductsProps {
  component?: IHomePageComponentDataObject;
  className?: string;
}

const TrendingProducts: React.FunctionComponent<ITrendingProductsProps> = ({ component, className }) => {

  const { t } = useTranslation();
  const sale = !component?.hideSaleProducts;

  return (
      <Section className={className} title={t('common.tranding_products')}>
        <TrendingProductsWrapper sale={sale} />
      </Section>
  );
};

export default TrendingProducts;
