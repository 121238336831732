import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acTrendingProductsGetAction } from '@actions/acCommon';
import SectionProductsList from '@components/common/SectionList/productsList';
import { GenderContext } from '@context/gender';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

interface Props {
  sale: boolean;
}

const TrendingProductsWrapper: React.FunctionComponent<Props> = ({ sale }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<TThunkDispatch>();

  const onGetTrendingProducts = useCallback((sexIdValue) => dispatch(acTrendingProductsGetAction(sexIdValue, sale)), [dispatch, sale]);

  const trendingProducts = useSelector((state: IStore) => state.common.trendingProducts);
  const loaded = useSelector((state: IStore) => state.common.loaded.trendingProducts);

  const { sexId } = useContext(GenderContext);

  useEffect(() => {
    if (!loaded) {
      onGetTrendingProducts(sexId);
    }
  }, []);

  useDidUpdateEffect(() => {
    onGetTrendingProducts(sexId);
  }, [sexId]);

  return (<SectionProductsList
    loaded={loaded}
    products={trendingProducts}
    wrapperListName={t('common.tranding')} />
  );

};

export default TrendingProductsWrapper;
